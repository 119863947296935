<template>
  <div class="xcx-preview">
    <!-- <span class="el-icon-close" @click="$parent.showPreview = false"></span> -->
    <div class="xcx-module-container">
      <!--控件部份-->
      <div v-for="(item, index) in list.controlList" :key="index">
        <!--页面背景-->
        <pagebg :base="item.base"></pagebg>
      </div>

      <div class="xcx-module">
        <!--DIY组件部份-->
        <Module :moduleList="list.moduleList" :prev="true" ref="module" />
      </div>
    </div>

    <div style="height: 80px">
      <!-- 占位符 -->
    </div>

    <NavModule></NavModule>

    <!-- 首页弹框 -->
    <!-- <van-dialog v-model="noticeVisible" show-cancel-button>
      <div
        class="notice-popup-content"
        style="max-height: 80vh; overflow-y: auto"
      >
        <h3 class="notice-title">首页公告</h3>
        <div class="ql-editor" v-html="noticeContent" />
      </div>
    </van-dialog> -->

    <el-dialog destroy-on-close append-to-body :visible.sync="noticeVisible" class="success-dialog el_dialog_radius"
      width="90%">
      <div class="success-dialog-container">
        <div class="title">系统公告</div>

        <div class="dilalog_img">
          <img class="fail" src="@/assets/image/notice_icon.png" />
        </div>

        <div class="ql-editor" v-html="noticeContent" />
        <div class="footer">
          <div class="but" @click="noticeVisible = false">关闭</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Module from "@/pages/template/mobile/module/module.vue";
import pagebg from "@/pages/template/mobile/module/pagebg.vue";
import { getWebTemplate, getIndexNotice } from "../api/index";
import NavModule from "@/pages/mobile/common/navModule.vue";

// import { registerWeChat } from "../api/user";
// import { setToken, setUserId } from "@/utils/auth";
export default {
  name: "mindex",
  components: { Module, pagebg, NavModule },
  data() {
    return {
      list: [],
      noticeVisible: false,
      noticeContent: undefined,
      loginForm: {
        weChatCode: undefined,
        platformType: "3",
      },
    };
  },
  mounted() {
    // this.checkIfWechatBrowser();
  },
  created() {
    this.initData();
    //加载首页弹框公告
    this.getNotice();
  },
  methods: {
    /**
     * 微信公众号自动登录
     */
    // checkIfWechatBrowser() {
    //   //获取参数
    //   const searchParams = new URLSearchParams(window.location.search);
    //   const code = searchParams.get("code");

    //   this.loginForm.weChatCode = code;

    //   registerWeChat(this.loginForm)
    //     .then((res) => {
    //       if (res.status === 0) {
    //         const { token, user_id } = res.result;
    //         setToken(token);
    //         setUserId(user_id);
    //         this.$router.push("/");
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     })
    //     .finally(() => {
    //       // 无论成功失败都会执行的逻辑
    //     });

    // },

    //初始化页面
    initData() {
      let data = {};
      getWebTemplate(data).then((response) => {
        if (response.status == 0) {
          this.list = response.data;
        }
      });
    },

    /**
     * 查询首页弹框公告
     */
    getNotice() {
      getIndexNotice().then((response) => {
        if (response.result) {
          this.noticeVisible = true;
          this.noticeContent = response.result.content;
          console.log(response);
        }
      });
    },
  },
};
</script>
<style scoped>
.notice-popup-content {
  padding: 20px;
  text-align: center;
}

.notice-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.notice-text {
  color: #999;
  line-height: 32px;
}

.success-dialog-container .el-dialog__body {
  padding: 20px 10px;
}
</style>
