var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[(_vm.footerData && _vm.footerData.list.length > 0)?[_c('van-tabbar',{attrs:{"active-color":"#ee0a24","inactive-color":"#000"},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},_vm._l((_vm.footerData.list),function(item,index){return _c('van-tabbar-item',{key:index,on:{"click":function($event){return _vm.toPath(item.link)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{style:({
            width: _vm.currentPath === item.navUrl ? '28px' : '24px',
            height: _vm.currentPath === item.navUrl ? '28px' : '24px',
          }),attrs:{"src":item.icon}})]},proxy:true}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])}),1)]:[_c('van-tabbar',{attrs:{"active-color":"#ee0a24","inactive-color":"#000"},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},[_c('van-tabbar-item',{attrs:{"to":"/mindex","replace":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{style:({
            width: _vm.currentIndex === 0 ? '28px' : '24px',
            height: _vm.currentIndex === 0 ? '28px' : '24px',
          }),attrs:{"src":require("@/assets/icon/home.png")}})]},proxy:true}])},[_c('span',[_vm._v("主页")])]),_c('van-tabbar-item',{attrs:{"to":"/cate","replace":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{style:({
            width: _vm.currentIndex === 1 ? '28px' : '24px',
            height: _vm.currentIndex === 1 ? '28px' : '24px',
          }),attrs:{"src":require("@/assets/icon/fenlei.png")}})]},proxy:true}])},[_c('span',[_vm._v("分类")])]),_c('van-tabbar-item',{attrs:{"to":"/contact","replace":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{style:({
            width: _vm.currentIndex === 2 ? '28px' : '24px',
            height: _vm.currentIndex === 2 ? '28px' : '24px',
          }),attrs:{"src":require("@/assets/icon/kefu.svg")}})]},proxy:true}])},[_c('span',[_vm._v("客服")])]),_c('van-tabbar-item',{attrs:{"to":"/my","replace":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{style:({
            width: _vm.currentIndex === 3 ? '28px' : '24px',
            height: _vm.currentIndex === 3 ? '28px' : '24px',
          }),attrs:{"src":require("@/assets/icon/wode.png")}})]},proxy:true}])},[_c('span',[_vm._v("我的")])])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }