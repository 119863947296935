<template>
  <div>
    <div class="container">
      <div class="mall-filter-wrap" style="box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 20px 0px">
        <div class="mall-filter-group">
          <div class="f-item">
            <div class="f-hd2">
              <span>目录</span>
            </div>
            <div class="f-bd">
              <div class="filter-icon" :class="{ cate_expand: isOpened }">
                <ul>
                  <li v-for="(value, index) in catesList" :key="index" @click="querSelectedIndex(value.id, index)"
                    :class="{ active: value.id == categoryPid }">
                    <div class="pic">
                      <van-image radius="5px" width="50" height="50" :src="value.prcUrl" />
                    </div>
                    <span class="name">{{ value.categoryName }}</span>
                  </li>
                </ul>
                <!-- <div class="open-close">展开</div> -->
                <div class="open-close" @click="toggleOpenClose">
                  {{ isOpened ? "收起" : "展开" }}
                </div>
              </div>
            </div>
          </div>

          <!-- 二级分类 -->
          <div class="f-item fenlei" v-if="childrenList.length > 0">
            <div class="f-hd2">
              <span>分类</span>
            </div>
            <div class="f-bd">
              <div class="filter-icon" :class="{ cate_expand: isTowOpened }">
                <ul>
                  <li v-for="(value, index) in childrenList" :key="index" @click="toProductList(value.id, index)"
                    :class="{ active: value.id == categoryId }">
                    <div class="pic">
                      <van-image width="50" height="50" :src="value.prcUrl" />
                    </div>
                    <span class="name">{{ value.categoryName }}</span>
                  </li>
                </ul>
                <div class="open-close" @click="toggleTwoOpenClose">
                  {{ isTowOpened ? "收起" : "展开" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="public-list" style="box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 20px 0px">
        <div class="list-table">
          <span>商品信息</span>
          <span>发货方式</span>
          <span>商品库存</span>
          <span>商品状态</span>
        </div>

        <el-empty description="没有找到您想要的商品" v-if="isEmpty"></el-empty>

        <!-- 滚动内容 -->
        <van-list v-model="loadingState" :finished="finished" finished-text="没有更多了" @load="loadMore">
          <div style="cursor: pointer" :class="{ overlay: v.inventoryState == '3' }" class="item"
            v-for="(v, k, i) in goodsList" :key="i" :title="v.name" @click="toProductDetails(v)">
            <div class="fl">
              <div class="block">
                <el-image :src="v.picUrl">
                  <template slot="error">
                    <el-image :src="$defaultImage" style="width: 100%;"></el-image>
                  </template>
                </el-image>
              </div>
            </div>
            <div class="fa">
              <div class="title">{{ v.productName }}</div>
              <div class="money">
                <!-- <em>￥{{ v.price }}</em> -->
                <span>￥</span>
                <span style="font-size: 24px; margin-right: 20px">{{
                  v.price
                }}</span>

                <template v-if="v.parValue">
                  <span style="color: #999; text-decoration: line-through">
                    原价：￥{{ v.parValue }}
                  </span>
                </template>
              </div>
              <div class="bq">
                <span>商品编号：{{ v.id }}</span>
              </div>
            </div>
            <div class="fr">
              <div class="fr-1">
                <div class="mode z1">{{ v.productTypeText }}</div>
              </div>

              <div class="fr-2">
                <!-- 库存 -->
                <div v-if="v.inventoryState">
                  <el-tag effect="dark" size="small">
                    {{ v.inventoryState }}
                  </el-tag>
                </div>

                <div v-else>
                  <el-tag effect="dark" size="small">
                    库存充足
                  </el-tag>
                </div>
              </div>

              <div class="fr-3">
                <span class="s1">正常销售</span>
              </div>
              <a @click="toProductDetails(v)">
                <i class="el-icon-shopping-bag-2"></i>购买</a>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import "@/assets/pc/css/style.css";
import "@/assets/pc/css/common.css";

import { queryProducCategorytList } from "@/api/index";

export default {
  data() {
    return {
      categoryPid: undefined,
      categoryId: undefined,

      childrenList: [],

      catesList: [],
      goodsLabelList: [],
      goodsList: [],
      queryParam: {
        goodsId: undefined,
      },
      queryGoodsRelationSpecsParam: {
        goodsId: undefined,
      },
      queryParamName: {
        specsName: undefined,
      },
      goodsName: "",
      goodsStatus: false,
      datas: [],
      pageNum: 1,
      pageSize: 10,
      isLoading: false, // 是否正在加载数据
      loadingState: false,
      finished: false,
      isEmpty: false,
      isFetching: false, // 是否有正在进行中的请求

      isOpened: false,
      isTowOpened: false,
    };
  },
  components: {
    // Header,
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      productList: (state) => state.index.productList,
      producCategorytList: (state) => state.index.producCategorytList,
    }),
  },
  created() {
    document.title = "商品列表";

    this.categoryPid = this.$route.query.categoryPid;
    this.categoryId = this.$route.query.categoryId;

    //查询一级分类
    this.queryCategorytList();

    this.getProductList();
  },
  // beforeRouteEnter(to, from, next) {
  //   const categoryId = to.query.categoryId;
  //   const categoryPid = to.query.categoryPid;

  //   if (categoryId) {
  //     next((vm) => {
  //       vm.pageNum = 1;
  //       vm.pageSize = 10;
  //       vm.finished = false;
  //       vm.categoryId = categoryId;
  //       vm.categoryPid = categoryPid;

  //       vm.goodsList = [];
  //       vm.getProductList();
  //     });
  //   } else {
  //     next();
  //   }
  // },
  methods: {
    /**
     * 加载更多
     */
    loadMore() {
      if (this.isFetching) {
        // 如果有正在进行中的请求，则不继续发起新的请求
        return;
      }
      this.pageNum = this.pageNum + 1;
      this.loadingState = true;
      this.getProductList();
    },

    //查询商品列表
    getProductList() {
      this.isFetching = true;

      let data = {
        categoryId: this.categoryId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        searchKeyword: this.$route.query.goodsName,
      };

      this.$store.dispatch("index/queryProductList", data).then(() => {
        this.goodsList = this.goodsList.concat(this.productList.productList);
        if (this.productList.productList.length == 0) {
          this.finished = true;
        }
        this.loadingState = false;
        this.isFetching = false;
      });
    },

    toProductList(id) {
      this.categoryId = id;

      this.goodsList = [];
      this.pageNum = 1;
      this.finished = false;

      this.loadingState = true;
      this.getProductList();
    },

    /**
     * 查询商品分类
     */
    queryCategorytList() {
      queryProducCategorytList({})
        .then((res) => {
          this.catesList = res.result.list;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          //查询二级分类
          this.queryTwoCategoryList();
        });
    },

    /**
     * 查询二级分类
     */
    queryTwoCategoryList() {
      for (let item of this.catesList) {
        if (item.id == this.categoryPid) {
          console.log(item);
          this.childrenList = item.children;
          break;
        }
      }
    },

    /**
     * 一级分类展开
     */
    toggleOpenClose() {
      this.isOpened = !this.isOpened;
    },

    /**
     * 二级分类展开
     */
    toggleTwoOpenClose() {
      this.isTowOpened = !this.isTowOpened;
    },

    //跳转商品详情界面
    toProductDetails(v) {
      this.$router.push(`/pgoodsDetail/${v.id}`);
    },

    querSelectedIndex(categoryId, index) {
      if (this.catesList[index].children) {
        this.childrenList = this.catesList[index].children;
      }

      this.categoryPid = categoryId;
      this.toProductList(categoryId);
    },
  },
};
</script>
<style scoped>
.scroll-container {
  height: 100%;
  overflow-y: auto;
}

.item.overlay {
  position: relative;
}

.item.overlay::after {
  content: "已售空";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.3);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-family: "Arial", sans-serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  z-index: 9999;
}

/* 分类 */
.mall-filter-wrap {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  /* border: 1px solid #ebedf0; */
  border-radius: 5px;
  background-color: #fff;
}

.mall-filter-wrap .f-item:first-child .f-hd2 {
  border-radius: 0;
}

.mall-filter-wrap .mall-filter-group .f-item:first-child .f-hd2 {
  border-radius: 5px 0 0 0;
}

.mall-filter-wrap .front-server,
.mall-filter-wrap .new-check-group {
  float: left;
}

.mall-filter-group {
  line-height: 30px;
}

.mall-filter-group .f-item {
  border-bottom: 1px solid #f0f2f5;
  position: relative;
  padding-left: 116px;
}

.mall-filter-group .f-item:after {
  content: "";
  visibility: hidden;
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
}

.mall-filter-group .f-item:last-child .f-hd {
  border-radius: 0 0 0 5px;
  padding-bottom: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mall-filter-group .f-item:first-child .f-hd {
  border-radius: 5px 0 0 0;
  padding-top: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mall-filter-group .f-hd,
.mall-filter-group .f-hd2 {
  width: 96px;
  color: #8d8e99;
  background-color: #fafbfc;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  white-space: nowrap;
  font-size: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mall-filter-group .f-hd2:before,
.mall-filter-group .f-hd:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: top;
}

.mall-filter-group .f-hd2 span,
.mall-filter-group .f-hd span {
  vertical-align: top;
  font-size: 14px;
  position: relative;
  /* top: 9px; */
}

.mall-filter-group .f-bd {
  float: left;
  width: 100%;
  padding: 15px 0;
  position: relative;
}

.mall-filter-group .filter-icon {
  padding-top: 12px;
  width: 1080px;
  max-height: 95px;
  overflow: hidden;
}

.mall-filter-group .cate_expand {
  max-height: none;
}

.mall-filter-group .filter-icon ul {
  font-size: 0;
}

.mall-filter-group .filter-icon li {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  padding: 0 5px;
  cursor: pointer;
  width: 98px;
}

.mall-filter-group .filter-icon .name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 45px;
  max-width: 115px;
}

.mall-filter-group .filter-icon li.active,
.mall-filter-group .filter-icon li:hover {
  color: #3b8cfe;
}

.mall-filter-group .filter-icon li.active .pic img,
.mall-filter-group .filter-icon li:hover .pic img {
  -webkit-filter: saturate(135%) brightness(106%);
  filter: saturate(135%) brightness(106%);
}

.mall-filter-group .filter-icon .pic {
  max-width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.mall-filter-group .filter-icon .pic img {
  vertical-align: middle;
  max-height: 100%;
  max-width: 100%;
  -webkit-filter: saturate(100%) brightness(100%);
  filter: saturate(100%) brightness(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  display: inline;
  border-radius: 10px;
}

.mall-filter-group .fenlei .filter-icon .pic img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 15px rgba(0, 184, 255, 0.2);
  box-shadow: 0 5px 15px rgba(0, 184, 255, 0.2);
  background: #fff;
  margin-bottom: 8px;
}

.mall-filter-group .filter-icon .name {
  font-size: 14px;
  text-align: center;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 45px;
  max-width: 115px; */
}

.mall-filter-group .filter-labels {
  float: left;
  max-width: 800px;
  overflow: hidden;
  margin: -4px 0;
  font-size: 14px;
}

.mall-filter-group .filter-labels span {
  float: left;
  line-height: 28px;
  padding: 0 10px;
  margin: 5px 5px 5px 0;
  cursor: pointer;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mall-filter-group .filter-labels span.active {
  background-color: #3b8cfe;
  border-radius: 2px;
  color: #fff;
}

.mall-filter-group .filter-labels span.active:hover {
  color: #fff;
}

.mall-filter-group .filter-labels span:hover {
  color: #3b8cfe;
}

.mall-filter-group .open-close {
  color: #8d8e99;
  cursor: pointer;
  position: absolute;
  right: 36px;
  top: 40px;
}

.mall-filter-group .open-close:after {
  content: "";
  display: inline-block;
  vertical-align: 3px;
  margin-left: 8px;
  width: 7px;
  height: 5px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAKBAMAAACK4lk+AAAAKlBMVEWNjpmNjpmNjpmNjpmNjpmNjpmNjpmNjpmNjpmNjpmNjpmNjpmNjpmNjpkrpcDkAAAADnRSTlMACSAhImZnaKi+v8Df4FxUuWEAAAApSURBVAjXYxBgYBBgmMbAuoDBt6DWgYH5xGEGBgZtBSgB5oIlwEpAigHqPglda1wQdAAAAABJRU5ErkJggg==) no-repeat 0 0;
}

.mall-filter-group .open-close.show:after {
  background-position: 0 -5px;
  vertical-align: 2px;
}

.mall-filter-group .filter-input,
.mall-filter-group .filter-search {
  float: left;
  position: relative;
  margin-left: 10px;
  overflow: hidden;
}

.mall-filter-group .filter-input input,
.mall-filter-group .filter-search input {
  border-radius: 2px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #dedfe0;
  color: #1b1f33;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

.mall-filter-group .filter-input input:focus,
.mall-filter-group .filter-search input:focus {
  border-color: #babcc4;
}

.mall-filter-group .filter-search input {
  width: 193px;
  padding: 0 35px 0 10px;
}

.mall-filter-group .filter-search .search-btn {
  position: absolute;
  right: 0;
  top: 1px;
  width: 35px;
  height: 28px;
  cursor: pointer;
}

.mall-filter-group .filter-search .search-btn:before {
  content: "";
  width: 17px;
  height: 17px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAiCAYAAAC5gzL5AAACPUlEQVR42qSVT0hUURTG38i4VCzMjdBIgbkQQlwbKNQqQhEdpF1IpCmmC1MXSgiTCqnoMGQLt6aTJEYb/+LQsvAPLkRUBrFNiIZLDes78A3cd+aaz+eBH8O799xv7rv3O+cF3g2OOUbkgjbwGBRybAvMghFw6Khoa33uZBjP1WAHvARroItsgBawy5y0CBoCcfAJNFr+UXYYY04NmDYnZSe3wDiYAmHbljkWpojk5mmRZvAXNPD3opC5F+AcvNIiT3hwx87lITmfefAukSKw7niPTXBPi/iJUy0iPrh/BYFisKdFZnkuNzwI5IAq8FWLREGAPgj8R0Dm3nPNsBb5BZ7RBx9pLMdiNpmrBZNck+bYaSaM0/ryiqucK+HrZvK5nmURs92OWP4OJ2VhP+jlQY6CEGhibpTl4dqJae9U4dkiZohEU2NBHx5xCQ0Ofdjza7aYUe1ngfxQqfbBU1AGbnMsCRK8nd9a7SD53XUmFaCbflgBXzgudVJH3oCli5pSBW9jEfRZ/lF22MGc11oog3bvAfOg07ZljsncAnNvapEwG06fh6b0lk2pTos84MGdeLgVyVnmwbtECsD2Fa53l+69dlM60yJJ40PlJe6Cn1okwXPJ9iCQBcrBNy0yRYN1eGhKnVwzoUWO6MSHIEJj2dpihDlzXJPm2CXuRIw0w1fc4lwRXzeVW8nbjNv6iVj+BwtQFj3iLeyzJU5wJ+3ESQnpKvYSNYbIAKo47kdEC7UEfZotzhqSD/yffwIMALugi4ZgAWwdAAAAAElFTkSuQmCC) no-repeat 0 0;
  position: absolute;
  left: 7px;
  top: 5px;
}

.mall-filter-group .filter-search .search-btn:hover:before {
  background-position: 0 -17px;
}

.mall-filter-group .filter-input input {
  width: 68px;
  padding: 0 4px;
  text-align: center;
}

.mall-filter-group .filter-input span {
  color: #1b1f33;
  font-size: 14px;
  margin: 0 15px;
}

.mall-filter-group .filter-input a {
  border-radius: 2px;
  border: 1px solid #959595;
  width: 56px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  color: #1b1f33;
  display: inline-block;
  font-size: 14px;
  margin-left: 15px;
}

.mall-filter-group .filter-input a:hover {
  background-color: #3b8cfe;
  border-color: #3b8cfe;
  color: #fff;
}

.mall-filter-group .has-close-labels {
  height: 34px;
  overflow: hidden;
}

.mall-filter-group .has-close-labels .filter-labels {
  max-width: 1008px;
}

.mall-filter-group .has-close-labels .filter-labels span {
  display: block;
}

.mall-filter-group .has-close-labels .open-close {
  top: 9px;
}

.mall-filter-group .filter-result-group {
  border: 0 none;
}

.mall-filter-group .filter-result-group .over-group {
  margin: -5px 0;
}

.mall-filter-group .filter-result-group .filter-checked {
  float: left;
  padding-bottom: 12px;
}

.mall-filter-group .filter-result-group .filter-checked .opt {
  position: relative;
  background-color: #f2f3f5;
  border: 1px solid #e3e5e8;
  border-radius: 2px;
  float: left;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 28px;
  padding: 0 35px 0 10px;
  margin: 5px 9px 4px 0;
  cursor: default;
}

.mall-filter-group .filter-result-group .filter-checked .del {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  line-height: 8px;
}

.mall-filter-group .filter-result-group .filter-checked .del:before {
  content: "x";
  display: inline-block;
  font-size: 16px;
  color: #a8a9b3;
}

.mall-filter-group .filter-result-group .filter-reset-btn {
  float: left;
  color: #8d8e99;
  margin: 5px 0 5px 10px;
}

.mall-filter-group .filter-result-group .filter-reset-btn:before {
  content: "";
  display: inline-block;
  vertical-align: -1px;
  margin-right: 3px;
  width: 15px;
  height: 13px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAaCAMAAABfE/ZQAAAAYFBMVEUAAABwjbuNjplRi9uNjplRi9tRi9uNjplRi9uNjplRi9uNjplRi9uNjpmNjplRi9uNjplRi9uNjpmNjplRi9tRi9tRi9uNjplRi9tRi9tRi9uNjplwjbuNjplRi9uNjplF8DnTAAAAHnRSTlMACOnon4inp6CAlYc8PJX6+uXlioB/Ozvpm4yMHZSE96kXAAAAqUlEQVQY001PRwLDIAxjE6DZu8P+/y/LJjoY5CWZePR6mRbdk4wBmXCC4UAjFbiGD11RBHriJ/etePqoGc2cMu0jE5ka9DAEHWkJE+s1Yep8Q1/3f9PzQxf13XQmmeovsOKfNf/kpeZxVq9iywLvZMfBJqEbZNwn4Q70gHdulHD4qHi9hysfeZfpBh4bAVlkdk9jvSb2Nt/MpP3tHlv0x+Pp7wL79H9l/3/jCwmWw/6rFQAAAABJRU5ErkJggg==) no-repeat 0 0;
}

.mall-filter-group .filter-result-group .filter-reset-btn:hover {
  text-decoration: underline;
}

.mall-filter-group .open-close {
  color: #8d8e99;
  cursor: pointer;
  position: absolute;
  right: 36px;
  top: 40px;
}
</style>
